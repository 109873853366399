import { Button, Collapse, Container, Form, Modal, Spinner, Stack } from 'react-bootstrap';
import { useNavigate, useParams, useSearchParams } from 'react-router';
import { DField, DTable } from '../components/DTable';
import { useCallback, useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import useLocalStorage from 'react-use-localstorage';
import { v4 as uuidv4 } from 'uuid';
import {
  CreateEventPlaytestSessionRequest,
  EventPlaytestSession,
  EventUser,
  EventUserHostedPlaytestSession,
  EventUserLocalDetails,
  GetEventUserRequest,
  JoinEventPlaytestSessionRequest,
  ListEventPlaytestSessionsRequest,
  UpdateEventPlaytestSessionRequest,
  UpdateEventUserRequest
} from '../../../models';
import dayjs from 'dayjs';
import { useEventRepository } from '../repositories/EventRepository';
import { uniqBy } from 'lodash';

export interface ListUsersRequest {
  eventUserIds: string[];
}

function EventActive() {
  const { eventId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const {
    getUser,
    updateUser,
    listPlaytestSessions,
    joinPlaytestSession,
    updatePlaytestSession,
    createPlaytestSession
  } = useEventRepository();

  const defaultEventUser: EventUserLocalDetails = Object.freeze({
    eventId: eventId || '',
    eventUserId: uuidv4(),
    eventUserSecret: uuidv4(),
    displayName: '',
    hostedPlaytestSessions: [],
    joinedPlaytestSessions: []
  });
  const [eventUser, setEventUser] = useState<EventUserLocalDetails>(defaultEventUser);

  const [getUserRequest, setGetUserRequest] = useState<GetEventUserRequest>();
  useEffect(() => {
    let ignore = false;
    void (async () => {
      if (getUserRequest) {
        const result = await getUser(getUserRequest);
        if (!ignore) {
          if (result) {
            setEventUser({ ...eventUser, ...result });
            setGetUserRequest(undefined);
          } else {
            // Failed to get user
            setGetUserRequest(undefined);
          }
        }
      }
    })();
    return () => {
      ignore = true;
    };
  }, [eventUser, getUser, getUserRequest]);

  const [updateUserRequest, setUpdateUserRequest] = useState<UpdateEventUserRequest>();
  useEffect(() => {
    let ignore = false;
    void (async () => {
      if (updateUserRequest) {
        await updateUser(updateUserRequest);
        if (!ignore) {
          setEventUser({ ...eventUser, ...updateUserRequest });
          setUpdateUserRequest(undefined);
          setGetUserRequest({ ...eventUser });
        }
      }
    })();
    return () => {
      ignore = true;
    };
  }, [eventUser, updateUser, updateUserRequest]);

  const [users, setUsers] = useState<EventUser[]>([]);
  const [listUsersRequest, setListUsersRequest] = useState<ListUsersRequest>();
  useEffect(() => {
    let ignore = false;
    void (async () => {
      if (listUsersRequest) {
        if (!ignore) {
          console.log(`List user request: ${JSON.stringify(listUsersRequest)}`);
          const newUsers = listUsersRequest.eventUserIds.filter(
            (requestedUserId) => !users.some((existingUser) => existingUser.eventUserId === requestedUserId)
          );
          const newUserDetails: EventUser[] = [];
          for (const eventUserId of newUsers) {
            const result = await getUser({ eventId: eventId || '', eventUserId });
            if (result) {
              newUserDetails.push(result);
            } else {
              // Failed to get user
            }
          }

          setUsers((existingUsers) => uniqBy([...existingUsers, ...newUserDetails], (_) => _.eventUserId));

          setListUsersRequest(undefined);
        }
      }
    })();
    return () => {
      ignore = true;
    };
  }, [eventId, getUser, listUsersRequest, users]);

  const [playtestSessions, setPlaytestSessions] = useState<EventPlaytestSession[]>([]);
  const [listPlaytestSessionsRequest, setListPlaytestSessionsRequest] = useState<ListEventPlaytestSessionsRequest>();
  useEffect(() => {
    let ignore = false;
    void (async () => {
      if (listPlaytestSessionsRequest) {
        const result = await listPlaytestSessions(listPlaytestSessionsRequest);
        if (!ignore) {
          if (result) {
            setPlaytestSessions([...result]);
            setListUsersRequest({
              eventUserIds: Array.from(new Set(result.map((session) => session.hostEventUserId)))
            });
            setListPlaytestSessionsRequest(undefined);
          } else {
            // Failed to get sessions
            setListPlaytestSessionsRequest(undefined);
          }
        }
      }
    })();
    return () => {
      ignore = true;
    };
  }, [eventUser.eventUserId, listPlaytestSessions, listPlaytestSessionsRequest]);

  const [joinPlaytestSessionRequest, setJoinPlaytestSessionRequest] = useState<JoinEventPlaytestSessionRequest>();
  useEffect(() => {
    let ignore = false;
    void (async () => {
      if (joinPlaytestSessionRequest) {
        await joinPlaytestSession(joinPlaytestSessionRequest);
        if (!ignore) {
          console.log(`Fetching user after joining playtest seession`);
          setGetUserRequest({ ...eventUser });
          setJoinPlaytestSessionRequest(undefined);
        }
      }
    })();
    return () => {
      ignore = true;
    };
  }, [joinPlaytestSessionRequest, setJoinPlaytestSessionRequest, setGetUserRequest, joinPlaytestSession, eventUser]);

  const [updatePlaytestSessionRequest, setUpdatePlaytestSessionRequest] = useState<UpdateEventPlaytestSessionRequest>();
  useEffect(() => {
    let ignore = false;
    void (async () => {
      if (updatePlaytestSessionRequest) {
        await updatePlaytestSession(updatePlaytestSessionRequest);
        if (!ignore) {
          console.log(`Fetching user after updating playtest session`);
          setGetUserRequest({ ...eventUser });
          setUpdatePlaytestSessionRequest(undefined);
        }
      }
    })();
    return () => {
      ignore = true;
    };
  }, [eventUser, updatePlaytestSession, updatePlaytestSessionRequest]);

  const [createPlaytestSessionRequest, setCreatePlaytestSessionRequest] = useState<CreateEventPlaytestSessionRequest>();
  useEffect(() => {
    let ignore = false;
    void (async () => {
      if (createPlaytestSessionRequest) {
        await createPlaytestSession(createPlaytestSessionRequest);
        if (!ignore) {
          console.log(`Fetching user after creating playtest session`);
          setGetUserRequest({ ...eventUser });
          setListPlaytestSessionsRequest({ eventId: eventId || '' });
          setCreatePlaytestSessionRequest(undefined);
        }
      }
    })();
    return () => {
      ignore = true;
    };
  }, [createPlaytestSession, createPlaytestSessionRequest, eventId, eventUser]);

  const [mode, setMode] = useState('join');

  const [eventUserString, setEventUserString] = useLocalStorage('anon-user');

  useEffect(() => {
    // Only load event user from string when loading from local storage
    if (eventUser === defaultEventUser && eventUserString !== '') {
      const localStorageUser = JSON.parse(eventUserString) as EventUserLocalDetails;
      setEventUser(localStorageUser);
      console.log(`Fetching user after parsing local storage`);
      setGetUserRequest({ ...localStorageUser });
    } else {
      const eventUserAsString = JSON.stringify(eventUser);

      if (eventUserString === '') {
        setShowModal(true);
        setShowMoreInfo(true);
      }

      if (eventUserAsString !== eventUserString) {
        setEventUserString(eventUserAsString);
      }
    }
  }, [setEventUser, setEventUserString, eventUserString, eventUser, defaultEventUser]);

  useEffect(() => {
    if (eventId) {
      setListPlaytestSessionsRequest({ eventId });
    }
  }, [eventId]);

  // DISABLE PERIODIC REFRESH

  // const [canRefresh, setCanRefresh] = useState(true);

  // useEffect(() => {
  //   if (eventId && canRefresh) {
  //     setListPlaytestSessionsRequest({ eventId });
  //   }

  //   const comInterval = setInterval(() => {
  //     if (eventId && canRefresh) {
  //       setListPlaytestSessionsRequest({ eventId });
  //     }
  //   }, 30_000); // 30 seconds
  //   return () => clearInterval(comInterval);
  // }, [setListPlaytestSessionsRequest, eventId]);

  // useEffect(() => {
  //   // Disable refreshing after 3 hour on page
  //   const comInterval = setInterval(() => {
  //     setCanRefresh(false);
  //   }, 3_600_000 * 3); // 3 hours
  //   return () => clearInterval(comInterval);
  // }, [setCanRefresh]);

  useEffect(() => {
    var urlJoinCode = searchParams.get('code');
    if (eventId && urlJoinCode) {
      setJoinPlaytestSessionRequest({ eventId: eventId, eventPlaytestSessionCode: urlJoinCode });

      const newParams = new URLSearchParams(searchParams);
      newParams.delete('code');
      navigate({ search: newParams.toString() });
    }
  }, [eventId, navigate, searchParams]);

  const [showModal, setShowModal] = useState(false);
  const [displayName, setDisplayName] = useState('');
  const [showMoreInfo, setShowMoreInfo] = useState(false);

  const onDisplayNameModalSubmit = useCallback(() => {
    setEventUser({ ...eventUser, displayName });
    setUpdateUserRequest({ ...eventUser, displayName });
    setShowModal(false);
  }, [displayName, eventUser]);

  return (
    <>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Body>
          <Form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              onDisplayNameModalSubmit();
            }}
          >
            <Form.Group>
              <Form.Label>Display Name</Form.Label>
              <Form.Control
                placeholder="Jane Doe"
                value={displayName}
                onChange={({ target }) => setDisplayName(target.value)}
              ></Form.Control>
              <Form.Text className="text-muted">Displayed to other users when hosting or joining a playtest.</Form.Text>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              onDisplayNameModalSubmit();
              setShowModal(false);
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <Container className="my-3">
        <h1>Protospiel MN 2025</h1>
        <p>
          Welcome <strong>{eventUser.displayName}</strong>! Use this website to find playtests to join, or players for
          games you host.{' '}
          <a
            href="#"
            onClick={(event) => {
              event.preventDefault();
              setShowMoreInfo(!showMoreInfo);
            }}
          >
            {showMoreInfo ? 'Less' : 'More'} Info
          </a>
        </p>
        <Collapse in={showMoreInfo}>
          <div>
            <p>
              Active playtests are listed below. To join a playtest: go to the location, ask the host for the code, and
              enter the code where it says <strong>Join</strong>.
            </p>
            <p>Joining playtests will cause games you host to appear higher on the list.</p>
          </div>
        </Collapse>
        <Stack direction="horizontal" gap={3} className="my-3">
          <Button
            onClick={() => {
              setMode('join');
              setListPlaytestSessionsRequest({ eventId: eventId || '' });
            }}
          >
            Join Playtest
          </Button>
          <Button onClick={() => setMode('host')}>Host Playtest</Button>
          <Button
            variant="outline-primary"
            onClick={() => {
              setShowModal(true);
              setDisplayName(eventUser.displayName);
            }}
          >
            Change Name
          </Button>
        </Stack>
        <hr />
        {mode === 'host' && (
          <HostPlaytest
            users={users}
            hostedPlaytestSession={eventUser.hostedPlaytestSessions?.[0]}
            eventId={eventId || ''}
            onChangeLocation={(eventPlaytestSesionId, location) => {
              setUpdatePlaytestSessionRequest({
                eventId: eventId || '',
                eventPlaytestSessionId: eventPlaytestSesionId,
                location
              });
            }}
            onRemoveUser={(eventPlaytestSesionId, eventUserId) => {
              var indexOf = eventUser.hostedPlaytestSessions.findIndex(
                (session) => session.eventPlaytestSessionId === eventPlaytestSesionId
              );
              var hostedPlaytestSession = eventUser.hostedPlaytestSessions?.[indexOf];

              setUpdatePlaytestSessionRequest({
                eventId: eventId || '',
                eventPlaytestSessionId: eventPlaytestSesionId,
                joinedEventUserIds: hostedPlaytestSession.joinedEventUserIds.filter((user) => user !== eventUserId)
              });
            }}
            onCreate={(location) => {
              setCreatePlaytestSessionRequest({
                eventId: eventId || '',
                location
              });
            }}
          />
        )}
        {mode === 'join' && (
          <JoinPlaytest
            userMe={eventUser}
            playtestSessions={playtestSessions}
            users={users}
            onJoin={(code) => setJoinPlaytestSessionRequest({ eventId: eventId || '', eventPlaytestSessionCode: code })}
          />
        )}
      </Container>
    </>
  );
}

interface EventPlaytest {
  host: string;
  location: string;
  type: string;
  score: number;
}

function getPlaytestSessionType(userMe: EventUser, playtestSession: EventPlaytestSession): string {
  if (userMe.eventUserId === playtestSession.hostEventUserId) {
    return 'Me';
  }

  const hostPlaysOfMyGames = userMe.hostedPlaytestSessions.filter((hostedSession) =>
    hostedSession.joinedEventUserIds.includes(playtestSession.hostEventUserId)
  );
  const myPlaysOfHostsGames = userMe.joinedPlaytestSessions.filter(
    (joinedSession) => joinedSession.hostEventUserId === playtestSession.hostEventUserId
  );

  if (hostPlaysOfMyGames.length > myPlaysOfHostsGames.length) {
    return 'Playback'; // Host has played your games more than you've played theirs
  } else if (hostPlaysOfMyGames.length > 0) {
    return 'Friendly'; // Host has played your game before but you've played theirs more already
  } else {
    return 'None';
  }
}

function getSessionTypeIcon(sessionType: string): string {
  switch (sessionType) {
    case 'Me':
      return '⭐';
    case 'Playback':
      return '🧪';
    case 'Friendly':
      return '🙂';
    default:
      return '';
  }
}

interface JoinPlaytestProps {
  userMe: EventUser;
  playtestSessions: EventPlaytestSession[];
  users: EventUser[];
  onJoin: (eventPlaytestSessionCode: string) => void;
}

function JoinPlaytest({ userMe, playtestSessions, users, onJoin }: JoinPlaytestProps) {
  const playtests: EventPlaytest[] = playtestSessions
    .filter((session) => session.location && dayjs(session.expiredTime).isAfter(dayjs()))
    .map((session) => ({
      host: users.find((user) => session.hostEventUserId === user.eventUserId)?.displayName ?? 'Loading...',
      location: session.location,
      type: getPlaytestSessionType(userMe, session),
      score: session.score
    }))
    .sort((a, b) => b.score - a.score);

  const fields: Record<string, DField<EventPlaytest>> = {
    icon: {
      header: '',
      showOnList: true,
      cell: (item) => <span title={item.type}>{getSessionTypeIcon(item.type)}</span>
    },
    host: { header: 'Host', showOnList: true, cell: (item) => <>{item.host}</> },
    location: { header: 'Location', showOnList: true, cell: (item) => <>{item.location}</> }
  };

  const [playtestSessionCode, setPlaytestSessionCode] = useState('');
  const [showJoinSubmitted, setShowJoinSubmitted] = useState(false);

  const joinSession = useCallback(() => {
    onJoin(playtestSessionCode);
    setShowJoinSubmitted(true);

    return setTimeout(() => {
      setShowJoinSubmitted(false);
      setPlaytestSessionCode('');
    }, 2000);
  }, [onJoin, playtestSessionCode, setShowJoinSubmitted, setPlaytestSessionCode]);

  return (
    <>
      <p>
        Enter the code shown on the hosts device to get credit for the playtest then press <strong>Join</strong>.
      </p>
      {!!userMe.joinedPlaytestSessions.length && (
        <p>
          Last joined playtest <strong>{userMe.joinedPlaytestSessions[0].eventPlaytestSessionCode}</strong>{' '}
          {dayjs(userMe.joinedPlaytestSessions[0].joinTime).fromNow()} hosted by{' '}
          <strong>
            {users.find((user) => userMe.joinedPlaytestSessions[0].hostEventUserId === user.eventUserId)?.displayName ??
              'Loading...'}
          </strong>
          .
        </p>
      )}
      <Stack direction="horizontal" gap={3} className="my-3">
        <Form
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            joinSession();
          }}
        >
          <Form.Control
            disabled={showJoinSubmitted}
            placeholder="A1B2"
            value={playtestSessionCode}
            onChange={({ target }) => setPlaytestSessionCode(target.value)}
          ></Form.Control>
        </Form>
        <Button disabled={showJoinSubmitted} variant="success" onClick={() => joinSession()}>
          Join {showJoinSubmitted && <Spinner size="sm" />}
        </Button>
      </Stack>
      <DTable fields={fields} items={playtests} />
    </>
  );
}

interface EventPlaytester {
  id: string;
  displayName: string;
}

interface HostPlaytestProps {
  eventId: string;
  hostedPlaytestSession?: EventUserHostedPlaytestSession;
  users: EventUser[];
  onChangeLocation: (eventPlaytestSessionId: string, location: string) => void;
  onRemoveUser: (eventPlaytestSessionId: string, eventUserId: string) => void;
  onCreate: (location: string) => void;
}

function HostPlaytest({
  eventId,
  hostedPlaytestSession,
  users,
  onChangeLocation,
  onRemoveUser,
  onCreate
}: HostPlaytestProps) {
  const playtesters: EventPlaytester[] =
    hostedPlaytestSession?.joinedEventUserIds.map((joinedUser) => ({
      id: joinedUser,
      displayName: users.find((user) => user.eventUserId === joinedUser)?.displayName || 'Loading...'
    })) || [];
  const fields: Record<string, DField<EventPlaytester>> = {
    icon: {
      header: '',
      showOnList: true,
      cell: (item) => <span title="First!">{item.id === playtesters[0].id ? '🥇' : ''}</span>
    },
    displayName: { header: 'Name', showOnList: true, cell: (item) => <>{item.displayName}</> },
    remove: {
      header: '',
      showOnList: true,
      cell: (item) => (
        <Button
          variant="danger"
          onClick={() => onRemoveUser(hostedPlaytestSession?.eventPlaytestSessionId || '', item.id)}
        >
          Remove
        </Button>
      )
    }
  };
  const [location, setLocation] = useState('');
  const [expiredTime, setExpiredTime] = useState(dayjs(hostedPlaytestSession?.expiredTime));

  useEffect(() => {
    setLocation(hostedPlaytestSession?.location || '');
    setExpiredTime(dayjs(hostedPlaytestSession?.expiredTime));
  }, [hostedPlaytestSession?.expiredTime, hostedPlaytestSession?.location]);

  const isExpired = expiredTime.isBefore();

  useEffect(() => {
    const interval = setInterval(() => setExpiredTime(dayjs(hostedPlaytestSession?.expiredTime)), 1000);
    return () => {
      clearInterval(interval);
    };
  }, [hostedPlaytestSession?.expiredTime]);

  return (
    <>
      <div>
        {isExpired ? (
          <p>
            Your playtest automatically ended {expiredTime.fromNow()}. Start a <strong>New Playtest</strong>.
          </p>
        ) : (
          <p>
            {!hostedPlaytestSession?.location && (
              <p>
                Your playtest is currently <strong>🕵️‍♀️ hidden</strong> because no location is set.
              </p>
            )}
            {hostedPlaytestSession?.location && (
              <p>
                Your playtest is currently <strong>💡 shown</strong> because a location is set.
              </p>
            )}
          </p>
        )}

        <Form
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            onChangeLocation(hostedPlaytestSession?.eventPlaytestSessionId || '', location);
          }}
        >
          <Form.Control
            placeholder="e.g. Table 1"
            value={location}
            onChange={({ target }) => setLocation(target.value)}
          ></Form.Control>
        </Form>
        <Stack direction="horizontal" gap={3} className="my-3">
          <Button
            disabled={!location || isExpired}
            variant={hostedPlaytestSession?.location ? 'outline-success' : 'success'}
            onClick={() => onChangeLocation(hostedPlaytestSession?.eventPlaytestSessionId || '', location)}
          >
            {hostedPlaytestSession?.location ? 'Change Location' : 'Show Playtest'}
          </Button>
          <Button
            disabled={isExpired}
            variant={hostedPlaytestSession?.location ? 'danger' : 'outline-danger'}
            onClick={() => onChangeLocation(hostedPlaytestSession?.eventPlaytestSessionId || '', '')}
          >
            Hide Playtest
          </Button>
          <Button variant="info" onClick={() => onCreate(location)}>
            New Playtest
          </Button>
        </Stack>
      </div>
      <ul className="my-3">
        <li>
          To get started, type in your location above then press <strong>Show Playtest</strong>.
        </li>
        <li>
          When you have enough playtesters, press <strong>Hide Playtest</strong>.
        </li>
        <li>
          When you have completed your playtest, press <strong>New Playtest</strong>.
        </li>
      </ul>
      <div className="my-3">
        <p>Playtesters can scan the QR code or enter the code to receive credit for participating.</p>
        <QRCode
          value={`https://www.playtestexchange.com/events/${eventId}?code=${hostedPlaytestSession?.eventPlaytestSessionCode}`}
        />
        <h1 style={{ fontSize: 100 }}>{hostedPlaytestSession?.eventPlaytestSessionCode}</h1>
      </div>
      <h2>Playtesters ({playtesters.length})</h2>
      <DTable fields={fields} items={playtesters} />
    </>
  );
}

export default EventActive;
