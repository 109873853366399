import React, { useEffect, useState } from 'react';
import { Alert, Button, Form, Modal, Table, TableProps } from 'react-bootstrap';

export interface DTableProps<T> extends TableProps {
  fields: Record<string, DField<T>>;
  items: T[];
  itemKey?: (item: T) => React.Key;
}

export interface DTableWithActionsProps<T> extends DTableProps<T> {
  showForm?: boolean;
  onShowForm?: (show: boolean) => void;
  selectedItem?: T | undefined;
  onSelectItem?: (item: T | undefined) => void;
  defaultItem: T;
  onCreate?: (item: T) => void;
  onUpdate?: (item: T) => void;
  error?: string | undefined;
}

export interface DField<T> {
  header: React.ReactElement | string;
  placeholder?: string;
  description?: string;
  descriptionFn?: (item: T) => React.ReactElement | string;
  type?: string;
  cell: (item: T) => React.ReactElement;
  conditional?: (item: T) => boolean;

  readOnly?: boolean;
  disabled?: boolean;
  showOnList?: boolean;
  showOnForm?: boolean;
  options?: { label: string; value: string }[];
  multiple?: boolean;
}

export interface DFormProps<T> {
  item: T | undefined;
  setItem: (item: T | undefined) => void;
  fields: Record<string, DField<T>>;
}

export function DForm<T>(props: DFormProps<T>) {
  const fields = [];
  if (!props.item) {
    return <Form></Form>;
  }
  const keys = Object.keys(props.fields || {}) as Array<keyof typeof props.fields>;
  for (const key of keys) {
    if (
      props.fields[key]?.showOnForm &&
      (props.fields[key]?.conditional ? props.fields[key]?.conditional?.(props.item) : true)
    ) {
      let control = <></>;

      if (props.fields[key]?.options) {
        const options = props.fields[key]?.options?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ));
        control = (
          <Form.Select
            disabled={props.fields[key]?.disabled}
            multiple={props.fields[key]?.multiple}
            value={
              props.fields[key]?.multiple
                ? (props.item[key as keyof typeof props.item] as string[])
                : (props.item[key as keyof typeof props.item] as string)
            }
            onChange={({ target }) => {
              if (props.fields[key]?.multiple) {
                const values = [];
                for (let i = 0; i < target.options.length; i++) {
                  if (target.options[i].selected) {
                    values.push(convertValue(target.options[i].value, props.fields[key].type));
                  }
                }
                props.setItem({ ...props.item, [key]: values } as T);
              } else {
                props.setItem({ ...props.item, [key]: convertValue(target.value, props.fields[key].type) } as T);
              }
            }}
          >
            <option key="none"></option>
            {options}
          </Form.Select>
        );
      } else {
        control = (
          <Form.Control
            type={props.fields[key]?.type}
            disabled={props.fields[key]?.disabled}
            readOnly={props.fields[key]?.readOnly}
            placeholder={props.fields[key]?.placeholder}
            value={props.item[key as keyof typeof props.item] as string}
            onChange={({ target }) => {
              props.setItem({ ...props.item, [key]: convertValue(target.value, props.fields[key].type) } as T);
            }}
          />
        );
      }

      fields.push(
        <Form.Group className="mb-3" controlId={key} key={key}>
          <Form.Label>{props.fields[key]?.header}</Form.Label>
          {control}
          <Form.Text className="text-muted">
            {props.fields[key]?.descriptionFn?.(props.item) || props.fields[key]?.description}
          </Form.Text>
        </Form.Group>
      );
    }
  }
  return <Form noValidate>{fields}</Form>;
}

function convertValue(valueString: string, valueType: string | undefined): any {
  switch (valueType) {
    case 'boolean':
      return valueString === 'true' ? true : false;
    case 'number':
      return Number.parseInt(valueString);
    default:
      return valueString;
  }
}

export function DTableWithActions<T>(props: DTableWithActionsProps<T>) {
  const updatedFields = {
    ...props.fields,
    itemActions: {
      header: (
        <Button
          variant="primary"
          onClick={() => {
            props.onShowForm?.(true);
            props.onSelectItem?.(structuredClone(props.defaultItem));
          }}
        >
          Create
        </Button>
      ),
      cell: (item: T) => (
        <Button
          variant="secondary"
          onClick={() => {
            props.onShowForm?.(true);
            props.onSelectItem?.(item);
          }}
        >
          {props.onUpdate ? 'Edit' : 'View'}
        </Button>
      ),
      showOnList: true,
      showOnForm: false
    }
  };
  return (
    <>
      <Modal show={props.showForm} onHide={() => props.onShowForm?.(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create / Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DForm item={props.selectedItem} setItem={props.onSelectItem || (() => {})} fields={props.fields} />
          {props.error && <Alert variant="danger">{props.error}</Alert>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => props.onShowForm?.(false)}>
            Close
          </Button>
          {(props.onUpdate || (props.selectedItem && !props.itemKey?.(props.selectedItem))) && (
            <Button
              variant="primary"
              onClick={() => {
                if (props.selectedItem) {
                  if (props.itemKey?.(props.selectedItem)) {
                    props.onUpdate?.(props.selectedItem);
                  } else {
                    props.onCreate?.(props.selectedItem);
                  }
                }
              }}
            >
              Save Changes
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <DTable fields={updatedFields} items={props.items} itemKey={props.itemKey} size={props.size} />
    </>
  );
}

export function DTable<T>({ fields, items, itemKey, size }: DTableProps<T>) {
  const rows = [];
  if (!items.length) {
    rows.push(
      <tr key="empty">
        <td colSpan={Object.keys(fields).length}>Empty</td>
      </tr>
    );
  }
  for (let item of items) {
    const tds = [];
    for (const field in fields) {
      if (fields[field].showOnList) {
        tds.push(<td key={field}>{fields[field].cell(item)}</td>);
      }
    }
    rows.push(<tr key={itemKey?.(item)}>{tds}</tr>);
  }
  const headers = [];
  for (const field in fields) {
    if (fields[field].showOnList) {
      headers.push(<th key={field}>{fields[field].header}</th>);
    }
  }
  return (
    <Table size={size}>
      <thead>
        <tr>{headers}</tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  );
}
