import './App.css';
import { Link, Outlet, Route, Routes } from 'react-router';
import FirstTimeDesigner from './pages/FirstTimeDesigner';
import Playtest from './pages/Playtest';
import Profile from './pages/Profile';
import { Navbar, Container, Nav, Badge, Row } from 'react-bootstrap';
import NotFound from './pages/NotFound';
import Login, { LoginButton } from './pages/Login';
import Designer from './pages/Designer';
import Info from './pages/Info';
import Game from './pages/Game';
import Home from './pages/Home';
import { useAuth0 } from '@auth0/auth0-react';
import Test from './pages/Test';
import { useSimpleRepository } from './repositories/SimpleRepository';
import { useEffect, useState } from 'react';
import UserPlaytest from './pages/UserPlaytest';
import { User } from '../../models';
import { useUserRepository } from './repositories/UserRepository';
import EventActive from './pages/EventActive';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Navigation />}>
        <Route index element={<Home />} />
        <Route path="info" element={<Info />} />
        <Route path="playtest" element={<Playtest />} />
        <Route path="designer" element={<Designer />}>
          <Route path="new" element={<Game />} />
          <Route path=":gameId" element={<Game />} />
        </Route>
        <Route path="designer-first-time" element={<FirstTimeDesigner />} />
        <Route path="profile" element={<Profile />} />
        <Route path="users/:username" element={<UserPlaytest />} />
        <Route path="test" element={<Test />} />
        <Route path="events/:eventId" element={<EventActive />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

function Navigation() {
  const { isAuthenticated } = useAuth0();
  const { getUserMe } = useUserRepository();

  const [user, setUser] = useState<User>();
  useEffect(() => {
    let ignore = false;
    if (isAuthenticated) {
      getUserMe().then((user) => {
        if (!ignore && user) {
          console.log('Fetched user: ' + JSON.stringify(user));
          setUser(user);
        }
      });
    }
    return () => {
      ignore = true;
    };
  }, [isAuthenticated]);

  return (
    <>
      <Navbar bg="light" expand="lg" sticky="top">
        <Container>
          <Navbar.Brand as={Link} to="/">
            Playtest Exchange
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/info">
                FAQ
              </Nav.Link>
              {isAuthenticated && (
                <>
                  <Nav.Link as={Link} to="/playtest">
                    Playtesters
                  </Nav.Link>
                  <Nav.Link as={Link} to="/designer">
                    Designers
                  </Nav.Link>
                  <Nav.Link as={Link} to="/profile">
                    Profile
                  </Nav.Link>
                  <Nav.Link disabled>
                    Communities
                  </Nav.Link>
                  <Nav.Link disabled>
                    Events
                  </Nav.Link>
                  <Nav.Link disabled>
                    Shop
                  </Nav.Link>
                </>
              )}
            </Nav>
            {isAuthenticated && (
              <>
                <Navbar.Text>{user?.displayName || 'User'}</Navbar.Text>
                <Nav className="me-auto">
                  <Badge className="m-1" bg="secondary">
                    🔵 {user?.playtestPoints || '0'} pp
                  </Badge>
                </Nav>
              </>
            )}
            <Nav className="me-auto">
              <LoginButton />
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Outlet />
    </>
  );
}

export default App;
