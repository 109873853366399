import { useCallback, useMemo } from 'react';
import { useSimpleRepository } from './SimpleRepository';
import {
  GetEventUserRequest,
  EventUser,
  UpdateEventUserRequest,
  ListEventPlaytestSessionsRequest,
  EventPlaytestSession,
  JoinEventPlaytestSessionRequest,
  UpdateEventPlaytestSessionRequest,
  CreateEventPlaytestSessionRequest
} from '../../../models';
import { Filter } from 'bad-words';

export interface UseEventRepositoryResult {
  getUser: (request: GetEventUserRequest) => Promise<EventUser | undefined>;
  updateUser: (request: UpdateEventUserRequest) => Promise<void>;
  listPlaytestSessions: (request: ListEventPlaytestSessionsRequest) => Promise<EventPlaytestSession[] | undefined>;
  createPlaytestSession: (request: CreateEventPlaytestSessionRequest) => Promise<void>;
  joinPlaytestSession: (request: JoinEventPlaytestSessionRequest) => Promise<void>;
  updatePlaytestSession: (request: UpdateEventPlaytestSessionRequest) => Promise<void>;
}

export const useEventRepository = (): UseEventRepositoryResult => {
  const { sendAnon } = useSimpleRepository();
  const filter = new Filter();

  const getUser = useCallback(
    async (request: GetEventUserRequest): Promise<EventUser | undefined> => {
      console.log(`Getting user: ${JSON.stringify(request)}`);
      try {
        const response = await sendAnon('GET', `/anon-api/events/${request.eventId}/users/${request.eventUserId}`);
        if (response.ok) {
          const eventUser: EventUser = JSON.parse(response.body);
          eventUser.displayName = filter.clean(eventUser.displayName);
          eventUser.hostedPlaytestSessions.forEach((session) => (session.location = filter.clean(session.location)));
          return eventUser;
        } else {
          console.warn(response.body);
          return undefined;
        }
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    [sendAnon]
  );

  const updateUser = useCallback(
    async (request: UpdateEventUserRequest): Promise<void> => {
      console.log(`Updating user: ${JSON.stringify(request)}`);
      try {
        const response = await sendAnon(
          'PUT',
          `/anon-api/events/${request.eventId}/users/${request.eventUserId}`,
          JSON.stringify({ displayName: request.displayName })
        );
        if (!response.ok) {
          console.warn(response.body);
        }
      } catch (e) {
        console.error(e);
      }
    },
    [sendAnon]
  );

  const listPlaytestSessions = useCallback(
    async (request: ListEventPlaytestSessionsRequest): Promise<EventPlaytestSession[] | undefined> => {
      console.log(`Listing playtest sessions: ${JSON.stringify(request)}`);
      try {
        const response = await sendAnon('GET', `/anon-api/events/${request.eventId}/playtests`);
        if (response.ok) {
          const playtestSessions: EventPlaytestSession[] = JSON.parse(response.body);
          playtestSessions.map((session) => (session.location = filter.clean(session.location)));
          return playtestSessions;
        } else {
          console.warn(response.body);
          return [];
        }
      } catch (e) {
        console.error(e);
        return [];
      }
    },
    [sendAnon]
  );

  const createPlaytestSession = useCallback(
    async (request: CreateEventPlaytestSessionRequest): Promise<void> => {
      console.log(`Creating playtest session: ${JSON.stringify(request)}`);
      try {
        const response = await sendAnon(
          'POST',
          `/anon-api/events/${request.eventId}/playtests`,
          JSON.stringify({ location: request.location })
        );
        if (!response.ok) {
          console.warn(response.body);
        }
      } catch (e) {
        console.error(e);
      }
    },
    [sendAnon]
  );

  const joinPlaytestSession = useCallback(
    async (request: JoinEventPlaytestSessionRequest): Promise<void> => {
      console.log(`Joining playtest session: ${JSON.stringify(request)}`);
      try {
        const response = await sendAnon(
          'POST',
          `/anon-api/events/${request.eventId}/joined-playtests`,
          JSON.stringify({ eventPlaytestSessionCode: request.eventPlaytestSessionCode?.trim() })
        );
        if (!response.ok) {
          console.warn(response.body);
        }
      } catch (e) {
        console.error(e);
      }
    },
    [sendAnon]
  );

  const updatePlaytestSession = useCallback(
    async (request: UpdateEventPlaytestSessionRequest): Promise<void> => {
      console.log(`Updating playtest session: ${JSON.stringify(request)}`);
      try {
        const response = await sendAnon(
          'PUT',
          `/anon-api/events/${request.eventId}/playtests/${request.eventPlaytestSessionId}`,
          JSON.stringify(request)
        );
        if (!response.ok) {
          console.warn(response.body);
        }
      } catch (e) {
        console.error(e);
      }
    },
    [sendAnon]
  );

  return useMemo(
    () => ({
      getUser,
      updateUser,
      listPlaytestSessions,
      createPlaytestSession,
      joinPlaytestSession,
      updatePlaytestSession
    }),
    [getUser, updateUser, listPlaytestSessions, createPlaytestSession, joinPlaytestSession, updatePlaytestSession]
  );
};
