import { AppState, Auth0Provider, User } from "@auth0/auth0-react";
import React from "react";
import { useNavigate } from "react-router";

export const Auth0ProviderWithNavigate = ({ children }: { children: React.ReactNode }) => {
    const navigate = useNavigate();

    const onRedirectCallback = (appState?: AppState) => {
      navigate((appState && appState.returnTo) || 'playtest');
    };

    return (
        <Auth0Provider
            domain="login.playtestexchange.com"
            clientId="BCQHdnOWXNAeo8FH32PtERLve5DK9AES"
            authorizationParams={{
                redirect_uri: window.location.origin
            }}
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    );
};